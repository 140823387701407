import { Injectable } from '@angular/core';
import { MainConversionInterface } from '../../../../core/interfaces/conversion/main-conversion.interface';
import { CurrentUserPreferenceStore } from '../../../../core/store/current-user-preference.store';
import { FormGroup } from '@angular/forms';
import { AuditDetailsDTO, AuditFormDTO, AuditListDTO } from '../dto/audit.dto';
import { TableConversion } from '../../../../shared/conversion/table.conversion';
import { AuditPlanConversion } from '../../audit-plan/conversion/audit-plan.conversion';
import { UserConversion } from '../../../organization/users/conversion/user.conversion';
import { getAuditableItemTypeTitleDetails } from '../../audit-plan/conversion/audit-plan-details-auditable-item.conversion';
import * as am5 from '@amcharts/amcharts5';
import { pieChartData } from '../../../../core/modals/chart.modal';

@Injectable({
  providedIn: 'root',
})
export class AuditConversion
  implements
    MainConversionInterface<AuditListDTO, AuditFormDTO, AuditDetailsDTO>
{
  private lang: string;

  constructor(
    private currentUserPreferenceStore: CurrentUserPreferenceStore,
    private tableConv: TableConversion,
    private userConv: UserConversion,
    private auditPlanConv: AuditPlanConversion
  ) {
    this.lang = this.currentUserPreferenceStore.languageKey;
  }

  resToList(response: any[]): AuditListDTO[] {
    //TODO
    if (!response) {
      return [];
    }
    const result = response.map((item) => {
      return {
        id: item.id,
        audit_plan_id: item.audit_plan_id,
        audit_plan_title: item.audit_plan_title,
        audit_objective: item.audit_plan_objective,
        audit_category: item['audit_category_title_' + this.lang],
        audit_purpose: item.audit_plan_purpose,
        team_lead: this.userConv.resToUserMinimalIdFixedDTO(item, 'team_lead'),
        audit_plan_description: item.audit_plan_description,
        audit_start_date: item.start_date,
        audit_end_date: item.end_date,
        audit_status: this.tableConv.resToColordItem(item, 'audit_status'),
      };
    });
    return result;
  }
  formGroupToForm(formGroup: FormGroup): AuditFormDTO {
    return {
      id: formGroup.get('id')?.value,
      audit_plan_id: formGroup.get('AuditPlanID')?.value,
      start_date: formGroup.get('StartDate')?.value,
      end_date: formGroup.get('EndDate')?.value,
    };
  }
  resToForm(response: any): any {
    return {
      id: response.id,
      AuditPlanID: response.audit_plan_id,
      StartDate: response.start_date,
      EndDate: response.end_date,
    };
  }
  resToDetails(response: any): AuditDetailsDTO {
    const organizationUnits = Array.from(
      new Set(
        response.audit_plan.auditable_items?.map(
          (item: any) =>
            item.auditable_item.responsible_unit['title_' + this.lang]
        )
      )
    ).join(', ');
    return {
      id: response.id,
      audit_plan_id: response.audit_plan_id,
      audit_plan: this.auditPlanConv.resToDetails(response.audit_plan),
      start_date: response.start_date,
      end_date: response.end_date,
      created_at: response.created_at,
      updated_at: response.updated_at,
      scope: response.audit_plan.auditable_items
        ? response.audit_plan.auditable_items.map((item: any) => {
            const details = getAuditableItemTypeTitleDetails(
              item.auditable_item,
              this.lang
            );
            return {
              id: item.auditable_item.id,
              item: details.title,
            };
          })
        : [],
      organization_unit: organizationUnits,
      created_by: this.userConv.resObjToUserMinimalDTO(response.created_by_id),
      updated_by: this.userConv.resObjToUserMinimalDTO(response.updated_by_id),
    };
  }

  convertResponseToAuditScheduleSummaryChartDTO(responce: any) :pieChartData[] {
    return [
      {
        category: 'Completed',
        count: responce.completed_count,
        sliceSettings: {
          fill: am5.color(0x28a745), // Green color for "Completed"
        }
      },
      {
        category: 'Pending',
        count: responce.pending_count,
        sliceSettings: {
          fill: am5.color(0xe0554d), // Yellow color for "Pending"
        }
      },
    ];
  }

  convertResponseToAuditFindingSummaryChartDTO(response: any) :pieChartData[] {
    return response.map((item: any) => ({
      category: item['title_'+this.lang],
      count: item.count,
      sliceSettings: {
        fill: am5.color(item.color_code),
      }
    }));
  }
}
