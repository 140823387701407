import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { Observable } from 'rxjs';
import { MainBaseService } from '../../../../core/services/main-base-service';
import { AuditFormDTO, AuditListResponseDTO } from '../dto/audit.dto';


@Injectable({
  providedIn: 'root',
})
export class AuditService extends MainBaseService<AuditListResponseDTO ,AuditFormDTO> {
  protected apiUrl = environment.apiUrl + '/audits'; 

  constructor(http: HttpClient) {
    super(http);
  }

  getTimeline(auditId: number): Observable<any> {
    const url = `${this.apiUrl}/${auditId}/timelines`;
    return this.http.get<any>(url);
  }

  getAuditScheduleSummaryChart(auditId: number): Observable<any> {
    const url = `${this.apiUrl}/${auditId}/schedules-summary`;
    return this.http.get<any>(url);
  }

  getAuditFindingSummaryChart(auditId: number): Observable<any> {
    const url = `${this.apiUrl}/${auditId}/audit-finding-summary`;
    return this.http.get<any>(url);
  }
}
